import React from 'react';

const TicketDigit = ({ digit, color, isShirt }) => {
  const isNumber7 = isShirt && +digit === 7;
  const isNumber10 = isShirt && +digit === 10;

  return (
    <li className={`current-ticket__number ${isShirt && 'emptyShirt'}`}>
      {!!digit ? (
        <div
          className={`current-ticket__number-text  ${isShirt && 'shirt'} ${isNumber7 && 'shirt7'} ${
            isNumber10 && 'shirt10'
          } ${color}`}>
          <div className="current-ticket__number-text-wrapper">{digit}</div>
        </div>
      ) : (
        ''
      )}
    </li>
  );
};

export default TicketDigit;
