import { DATA_TYPES, CLOSE_ERRORS_POPUP } from '../actionTypes';

export const setError = (value) => {
  return { type: DATA_TYPES.SET_ERRORS, value };
};

export const setMinBetWarning = () => {
  return { type: DATA_TYPES.MIN_BET_WARNING };
};

export const setMaxBetWarning = () => {
  return { type: DATA_TYPES.MAX_BET_WARNING };
};

export const closeErrorsPopup = () => {
  return { type: CLOSE_ERRORS_POPUP };
};
