import { getToken, url } from '../../constants/api';
import dataParser from '../../helpers/dataParser';
import axios from 'axios';
import { DATA_TYPES } from '../actionTypes';
import { replaceTickets } from './ticketActions';
import { addRaffleInfo } from './raffleInfoActions';
import { resetIsFetching, setIsFetching } from './isFetchingActions';
import { setSelectedAmount } from './amountActions';
import { goToGameScene, goToMenuScene } from './activeSceneActions';
import { setKenoType } from './kenoTypeActions';
import { getKenoType } from '../../helpers/game';
import { KENO_TYPES } from '../../constants/game';
import { setError } from './errorActions';
import { setGameType } from './userDataActions';
import { GAME_TYPES } from '../../constants/names';

const {
  SET_BALL_FREQUENCY,
  SET_FREQUENCY_TAB,
  SET_DRAW_HISTORY,
  SET_USER_HISTORY,
  SET_SEC_TILL_DRAW,
  SET_DRAW_INFO,
  SET_BET_AMOUNT_OPT,
  SET_CURRENCY,
  SET_GAME_VERSIONS,
  SET_USER_BALANCE,
  SET_USER_ID,
  SET_GAME_LEADERBOARD,
  UPDATE_GAME_LEADERBOARD,
  SET_ACTIVE_LANGUAGE,
  TOGGLE_SOUND,
  SET_AMOUNT_RANGES,
  SET_HOME_BUTTON,
} = DATA_TYPES;

const { KENO_1, KENO_3 } = KENO_TYPES;

const { KENO, NUMBERS } = GAME_TYPES;

const kenoType = getKenoType();
export const setFrequencyTab = (value) => ({ type: SET_FREQUENCY_TAB, value });
const setBallFrequency = (value) => ({ type: SET_BALL_FREQUENCY, value });
const setUserBalance = (value) => ({ type: SET_USER_BALANCE, value });
const setUserId = (value) => ({ type: SET_USER_ID, value });
const setDrawHistory = (value) => ({ type: SET_DRAW_HISTORY, value });
const setUserHistory = (value) => ({ type: SET_USER_HISTORY, value });
export const setSecTillDraw = (value) => ({ type: SET_SEC_TILL_DRAW, value });
const setDrawInfo = (value) => ({ type: SET_DRAW_INFO, value });
const setHomeButtonAvtive = (value) => ({ type: SET_HOME_BUTTON, value });
const setBetAmountOption = (value) => ({ type: SET_BET_AMOUNT_OPT, value });
const setCurrency = (value) => ({ type: SET_CURRENCY, value });
const setAmountRanges = (value) => ({ type: SET_AMOUNT_RANGES, value });
const setGameVersions = (value) => ({ type: SET_GAME_VERSIONS, value });
const setGameLeaderBoard = (value) => ({ type: SET_GAME_LEADERBOARD, value });
const updateGameLeaderBoard = (value) => ({
  type: UPDATE_GAME_LEADERBOARD,
  value,
});

export const toggleSound = () => ({ type: TOGGLE_SOUND });

export const setActiveLanguage = (value) => ({
  type: SET_ACTIVE_LANGUAGE,
  value,
});

export function getGameVersions() {
  return (dispatch) => {
    return new Promise((resolve) => {
      axios
        .get(`${url}/get-game-versions`, {
          params: {
            token: getToken(),
            duration: 1,
          },
        })
        .then((response) => {
          const gameData = response?.data;

          if (gameData.error) {
            dispatch(setError(gameData.message));
            return;
          }

          const { possibleGameVersions, kenoMode } = gameData.data;
          const parsedData = dataParser(gameData.data);
          const { betAmountOption, currency } = parsedData;

          dispatch(setGameType(kenoMode === 'numbers' ? NUMBERS : KENO));

          if (possibleGameVersions.length === 1) {
            const [kenoType] = possibleGameVersions;
            dispatch(setHomeButtonAvtive(false));
            dispatch(goToGameScene());
            dispatch(goToMenuScene());
            dispatch(setKenoType(Number(kenoType)));
          } else {
            dispatch(setBetAmountOption(betAmountOption));
            dispatch(setCurrency(currency));
            dispatch(goToMenuScene());
          }
          dispatch(setGameVersions(possibleGameVersions));
        })
        .catch((e) => {
          console.log('keno/get-info', e);
          `${e}` === 'Error: Network Error'
            ? dispatch(setError('Error. Low internet connection.'))
            : dispatch(setError('Something went wrong, please try again.'));
        });
    });
  };
}

export function getGameData(kenoType = 1) {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch(setIsFetching());
      axios
        .get(`${url}/get-generic-info`, {
          params: {
            token: getToken(),
            duration: kenoType,
          },
        })
        .then((response) => {
          const gameData = response.data.data;
          const parsedData = dataParser(gameData);
          const {
            currency,
            ballFrequency,
            drawHistoryTickets,
            userHistoryTickets,
            secTillDraw,
            drawInfo,
            betAmountOption,
            userCurrentTickets,
            userData,
            amountRanges,
          } = parsedData;
          // TODO
          dispatch(setCurrency(currency));
          dispatch(setAmountRanges(amountRanges));
          dispatch(setUserId(userData.userId));
          dispatch(setSecTillDraw(secTillDraw));
          dispatch(setBallFrequency(ballFrequency));
          dispatch(setDrawHistory(drawHistoryTickets));
          dispatch(setUserHistory(userHistoryTickets));
          dispatch(setDrawInfo(drawInfo));
          dispatch(setBetAmountOption(betAmountOption));
          dispatch(replaceTickets(userCurrentTickets));
          dispatch(setSelectedAmount(betAmountOption.minBet));
          dispatch(
            addRaffleInfo({
              digits: drawHistoryTickets[0].digits,
              raffleTickets: userHistoryTickets[drawInfo.drawId - 1] || [],
            }),
          );
          resolve();
        })
        .catch((e) => {
          console.log('keno/get-info', e);
          `${e}` === 'Error: Network Error'
            ? dispatch(setError('Error. Low internet connection.'))
            : dispatch(setError('Something went wrong, please try again.'));
        })
        .finally(() => {
          dispatch(resetIsFetching());
        });
    });
  };
}

export function getSecondTillDraw(kenoType = 1) {
  return (dispatch) => {
    return new Promise((resolve) => {
      axios
        .get(`${url}/get-seconds-till-draw`, {
          params: {
            token: getToken(),
            duration: kenoType,
          },
        })
        .then((response) => {
          const gameData = response.data.data;
          const parsedData = dataParser(gameData);
          const { secTillDraw } = parsedData;

          dispatch(setSecTillDraw(secTillDraw));
          if (gameData.error) {
            dispatch(setError(gameData.message));
            return;
          }
          resolve(gameData.secondsTillDraw);
        })
        .catch((e) => {
          console.log(e); //+
          `${e}` === 'Error: Network Error'
            ? dispatch(setError('Error. Low internet connection.'))
            : dispatch(setError('Something went wrong, please try again.'));
        });
    });
  };
}

export function getUserData(kenoType = 1) {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch(setIsFetching());
      axios
        .get(`${url}/get-balance`, {
          params: {
            token: getToken(),
          },
        })
        .then((response) => {
          const userBalance = response.data.data;
          dispatch(setUserBalance(userBalance.balance));

          resolve();
        })
        .catch((e) => {
          console.log('keno/get-balance', e);
          `${e}` === 'Error: Network Error'
            ? dispatch(setError('Error. Low internet connection.'))
            : dispatch(setError('Something went wrong, please try again.'));
        })
        .finally(() => {
          dispatch(resetIsFetching());
        });
    });
  };
}

export function getDrawResults(kenoType = 1) {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch(setIsFetching());
      axios
        .get(`${url}/get-draw-results`, {
          params: {
            token: getToken(),
            duration: kenoType,
            page: 0,
          },
        })
        .then((response) => {
          const gameData = response.data.data;
          const parsedData = dataParser(gameData);
          const { drawHistoryTickets, userHistoryTickets, secTillDraw, drawInfo } = parsedData;
          dispatch(setSecTillDraw(secTillDraw));
          dispatch(setDrawHistory(drawHistoryTickets));
          dispatch(setDrawInfo(drawInfo));
          dispatch(replaceTickets([]));
          if (drawHistoryTickets[0].id === drawInfo.drawId - 1) {
            dispatch(
              addRaffleInfo({
                digits: drawHistoryTickets[0].digits,
                raffleTickets: userHistoryTickets[drawInfo.drawId - 1] || [],
              }),
            );
          } else {
            dispatch(setError('Error. Low internet connection.'));
            dispatch(
              addRaffleInfo({
                digits: [],
                raffleTickets: userHistoryTickets[drawInfo.drawId - 1] || [],
              }),
            );
          }
          resolve();
        })
        .catch((e) => {
          console.log('keno/get-info', e);
          `${e}` === 'Error: Network Error'
            ? dispatch(setError('Error. Low internet connection.'))
            : dispatch(setError('Something went wrong, please try again.'));
        })
        .finally(() => {
          dispatch(resetIsFetching());
        });
    });
  };
}

export function getUserCurrentState(kenoType = 1) {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch(setIsFetching());
      axios
        .get(`${url}/get-user-current-state`, {
          params: {
            token: getToken(),
            duration: kenoType,
            page: 0,
          },
        })
        .then((response) => {
          const gameData = response.data.data;
          const parsedData = dataParser(gameData);
          const {
            currency,
            userCurrentTickets,
            userData: { userBalance },
          } = parsedData;

          dispatch(setCurrency(currency));
          dispatch(replaceTickets(userCurrentTickets));
          dispatch(setUserBalance(userBalance));
          resolve();
        })
        .catch((e) => {
          console.log('keno/get-info', e);
          `${e}` === 'Error: Network Error'
            ? dispatch(setError('Error. Low internet connection.'))
            : dispatch(setError('Something went wrong, please try again.'));
        })
        .finally(() => {
          dispatch(resetIsFetching());
        });
    });
  };
}
