import { CLOSE_ERRORS_POPUP, DATA_TYPES } from '../actionTypes';
import { forMap } from '../../helpers/general';
import { COUNT_FOR_HISTORY } from '../actionTypes';
import { WARNING_TYPES } from '../../constants/game';
const {
  SET_CURRENCY,
  SET_USER_BALANCE,
  SET_USER_ID,
  SET_BALL_FREQUENCY,
  SET_FREQUENCY_TAB,
  SET_DRAW_HISTORY,
  SET_USER_HISTORY,
  SET_BET_AMOUNT_OPT,
  SET_SEC_TILL_DRAW,
  SET_DRAW_INFO,
  UPDATE_USER_DATA,
  SET_GAME_VERSIONS,
  SET_ERRORS,
  SET_FRONT_SEC_TILL_DRAW,
  SET_GAME_LEADERBOARD,
  UPDATE_GAME_LEADERBOARD,
  SET_ACTIVE_LANGUAGE,
  TOGGLE_SOUND,
  SET_AMOUNT_RANGES,
  SET_HOME_BUTTON,
  MIN_BET_WARNING,
  MAX_BET_WARNING,
} = DATA_TYPES;

const countReducer = (state = 0, action) => {
  switch (action.type) {
    case COUNT_FOR_HISTORY: {
      return (state += 1);
    }
    default:
      return state;
  }
};

const ballFrequencyInitState = { activeTab: null, balls: {} };

const userDataInitState = { userId: 1, userBalance: '' };

const userDataReducer = (state = userDataInitState, action) => {
  switch (action.type) {
    case SET_USER_BALANCE: {
      return { ...state, userBalance: action.value };
    }
    case UPDATE_USER_DATA: {
      return { ...state, userBalance: action.value };
    }
    case SET_USER_ID: {
      return { ...state, userId: action.value };
    }
    default:
      return state;
  }
};

const ballFrequencyReducer = (state = ballFrequencyInitState, action) => {
  switch (action.type) {
    case SET_BALL_FREQUENCY: {
      return action.value;
    }
    case SET_FREQUENCY_TAB: {
      return { ...state, activeTab: action.value };
    }
    default:
      return state;
  }
};

const drawHistoryReducer = (state = [], action) => {
  switch (action.type) {
    case SET_DRAW_HISTORY: {
      return action.value;
    }
    default:
      return state;
  }
};

const userHistoryReducer = (state = [], action) => {
  switch (action.type) {
    case SET_USER_HISTORY: {
      return action.value;
    }
    default:
      return state;
  }
};

const betAmountOptionReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_BET_AMOUNT_OPT: {
      return action.value;
    }
    default:
      return state;
  }
};

const secTillDrawReducer = (state = null, action) => {
  switch (action.type) {
    case SET_SEC_TILL_DRAW: {
      return action.value;
    }
    default:
      return state;
  }
};

const drawInfoReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_DRAW_INFO: {
      return action.value;
    }
    default:
      return state;
  }
};

const amountRangesReducer = (state = [], action) => {
  switch (action.type) {
    case SET_AMOUNT_RANGES: {
      return action.value;
    }
    default:
      return state;
  }
};

const currencyReducer = (state = '', action) => {
  switch (action.type) {
    case SET_CURRENCY: {
      return action.value;
    }
    default:
      return state;
  }
};

const gameVersionsReducer = (state = [], action) => {
  switch (action.type) {
    case SET_GAME_VERSIONS: {
      return action.value;
    }
    default:
      return state;
  }
};

const getGameLeaderInitialState = {
  error: true,
  list: [
    { place: 0, playerId: 0, points: 0, ticketsCount: 0, possibleWin: 0 },
    { place: 0, playerId: 0, points: 0, ticketsCount: 0, possibleWin: 0 },
    { place: 0, playerId: 0, points: 0, ticketsCount: 0, possibleWin: 0 },
  ],
  isFinished: 0,
};

const gameLeaderboard = (state = getGameLeaderInitialState, action) => {
  switch (action.type) {
    case SET_GAME_LEADERBOARD: {
      const listLength = action.value.list.length;
      if (listLength < 3) {
        const copyData = { ...action.value };
        for (let i = 0; i <= 3 - listLength; i++) {
          copyData.list.push({ place: 0, playerId: 0, points: 0, possibleWin: 0 });
        }
        return copyData;
      } else {
        return action.value;
      }
    }
    case UPDATE_GAME_LEADERBOARD: {
      state.list = [...state.list, ...action.value.list];
      return { ...state };
    }
    default:
      return state;
  }
};

const { MIN_BET, MAX_BET } = WARNING_TYPES;

const errorsPopup = (state = false, action) => {
  switch (action.type) {
    case SET_ERRORS: {
      return action.value;
    }
    case CLOSE_ERRORS_POPUP: {
      return false;
    }
    case MIN_BET_WARNING: {
      return MIN_BET;
    }
    case MAX_BET_WARNING: {
      return MAX_BET;
    }
    default:
      return state;
  }
};

const frontSecTillDraw = (state = null, action) => {
  switch (action.type) {
    case SET_FRONT_SEC_TILL_DRAW: {
      return action.payload;
    }
    default:
      return state;
  }
};

const activeLanguageReducer = (state = 'en', action) => {
  switch (action.type) {
    case SET_ACTIVE_LANGUAGE: {
      return action.value;
    }
    default:
      return state;
  }
};

const storageSound = JSON.parse(localStorage.getItem('sound'));
const initialSound = storageSound ?? true;

const toggleSoundReducer = (state = initialSound, action) => {
  switch (action.type) {
    case TOGGLE_SOUND: {
      return !state;
    }
    default:
      return state;
  }
};

const isHomeButtonAvtive = (state = true, action) => {
  switch (action.type) {
    case SET_HOME_BUTTON: {
      return (state = action.value);
    }
    default:
      return state;
  }
};

export default {
  errorsPopup,
  currencyReducer,
  userDataReducer,
  ballFrequencyReducer,
  drawHistoryReducer,
  userHistoryReducer,
  betAmountOptionReducer,
  secTillDrawReducer,
  drawInfoReducer,
  gameVersionsReducer,
  frontSecTillDraw,
  gameLeaderboard,
  activeLanguageReducer,
  toggleSoundReducer,
  amountRangesReducer,
  countReducer,
  isHomeButtonAvtive,
};
