import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useLanguageData } from '../../Context/LanguageContext';
import { closeErrorsPopup } from '../../redux/actions/errorActions';
import { WARNING_TYPES } from '../../constants/game';

const { MIN_BET, MAX_BET } = WARNING_TYPES;

const ErrorsPopup = ({ errorsPopup, closeErrorsPopup, betAmountOption }) => {
  const languageData = useLanguageData();
  // const [errorMessage, serErrorMessage] = useState();
  const errorMessage =
    errorsPopup === MIN_BET
      ? `${languageData['Minimum bet is']} ${betAmountOption.minBet}. ${languageData['Please, enter an amount within the allowed limit.']}`
      : errorsPopup === MAX_BET
      ? `${languageData['Maximum bet is']} ${betAmountOption.maxBet}. ${languageData['Please, enter an amount within the allowed limit.']}`
      : '';

  return (
    <div className={`${errorsPopup ? 'errorPopup active' : 'errorPopup'}`}>
      <div className="errorPopup__container">
        <div style={{ maxHeight: '10px', maxWidth: '10px' }}>
          <button onClick={() => closeErrorsPopup()} className="popup__close">
            Close popup
          </button>
        </div>
        {<div className="errorPopup__title">{errorMessage || languageData[errorsPopup]}</div>}
      </div>
      <div className="popup__wrapper" />
    </div>
  );
};

const mapStateToProps = ({ errorsPopup, betAmountOptionReducer }) => ({
  errorsPopup,
  betAmountOption: betAmountOptionReducer,
});

const mapDispatchToProps = {
  closeErrorsPopup,
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorsPopup);
