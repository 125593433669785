import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { resetBetButtons } from '../../redux/actions/betBtnActions';
import { sendTicket } from '../../redux/actions/ticketActions';
import { getStoreState } from '../../redux/store';
import { setError, setMinBetWarning } from '../../redux/actions/errorActions';
import { useLanguageData } from '../../Context/LanguageContext';
import { setIsBetCanceled } from '../../redux/actions/optionActions';
import { setSelectedAmount } from '../../redux/actions/amountActions';
import { useSoundConstext } from '../../Context/SoundContext';
import { countForGettingHistory } from '../../redux/actions/countForGettingHistory';

const ConfirmButton = ({
  isButtonActive,
  changeButtonActivity,
  userData,
  selectedBetBtns,
  isBetCanceled,
  setIsBetCanceled,
  resetBetButtons,
  sendTicket,
  selectedAmount,
  tickets,
  isRaffleActive,
  setError,
  frontSecTillDraw,
  betAmountOption,
}) => {
  const { userBalance } = userData;
  const dispatch = useDispatch();
  const languageData = useLanguageData();
  const useSoundData = useSoundConstext();
  const isSoundActive = useSelector((state) => state.toggleSoundReducer);

  const areButtonsChosen = selectedBetBtns.length > 0;

  useEffect(() => {
    if (isBetCanceled) {
      setIsBetCanceled(false);
    }
  }, [isRaffleActive]);

  async function handleConfirm() {
    if (areButtonsChosen && selectedAmount >= betAmountOption.minBet) {
      const ticketHeadNumber = tickets.length + 1;
      changeButtonActivity(false);
      if (userBalance >= selectedAmount) {
        await sendTicket(makeTicket(ticketHeadNumber));
        if (isRaffleActive) {
          dispatch(
            setError(
              'Participation in the current draw is closed. The placed bet will be accepted for the next draw.',
            ),
          );
        }
        //for geting userhistory
        dispatch(countForGettingHistory());
        changeButtonActivity(true);
        isSoundActive && useSoundData.bet();
      } else {
        setError(
          'Your current balance is not enough to participate in the draw, please, refill it.',
        );
        changeButtonActivity(true);
        setIsBetCanceled(true);
      }
    } else if (selectedAmount < betAmountOption.minBet) {
      dispatch(setMinBetWarning());
      dispatch(setSelectedAmount(betAmountOption.minBet));
    } else if (!areButtonsChosen) {
      dispatch(
        setError('To participate in the draw, please, select your ticket numbers up to 10.'),
      );
    } else {
      console.log('error');
    }
  }

  const onResetClick = () => {
    isBetCanceled && setIsBetCanceled(false);
    resetBetButtons();
  };

  return (
    <div className="clear-confirm">
      <button className="clear-confirm__clear" onClick={onResetClick} />
      {isRaffleActive && !isBetCanceled && (
        <div className="next-drow-bet_text">
          {languageData['Your bet will be accepted for the next draw']}
        </div>
      )}
      {isBetCanceled && (
        <div className="bet_is_not_accepted">
          <div>{languageData.betIsNotAccepted}</div>
        </div>
      )}
      <div className="confirm_button_wrapper">
        <button
          className={`clear-confirm__confirm-bet ${areButtonsChosen ? '' : 'disable'}`}
          onClick={frontSecTillDraw >= 6 ? handleConfirm : null}>
          {languageData.bet.toUpperCase()}
        </button>
      </div>
    </div>
  );
};

function makeTicket(ticketHeadNumber) {
  const date = new Date().toISOString();
  const { selectedBetBtns: digits, selectedAmount: betMoney } = getStoreState();

  return {
    id: ticketHeadNumber,
    date,
    digits,
    betMoney,
  };
}

const mapStateToProps = ({
  selectedBetBtns,
  selectedAmount,
  lastPayout,
  options: { isBetCanceled },
  isRaffleActive,
  ticketReducer,
  userDataReducer,
  frontSecTillDraw,
  betAmountOptionReducer,
}) => ({
  userData: userDataReducer,
  selectedBetBtns,
  selectedAmount,
  lastPayout,
  isRaffleActive,
  tickets: ticketReducer,
  frontSecTillDraw,
  isBetCanceled,
  betAmountOption: betAmountOptionReducer,
});

const mapDispatchToProps = {
  sendTicket,
  setError,
  resetBetButtons: () => resetBetButtons,
  setIsBetCanceled,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmButton);
