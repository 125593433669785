import {
  BET_BTN_TYPES,
  TICKET_TYPES,
  TAB_TYPES,
  AMOUNT_TYPES,
  PAYOUT_TYPES,
  DURATION_TYPES,
  CHANGE_SCENE,
  SHOW_POPUP,
  RAFFLE_TYPES,
  ADD_RAFFLE_INFO,
  PULLED_DIGIT_TYPES,
  IS_FETCHING_TYPES,
  SET_CONGRATS,
  POPUP_TYPES,
  CHANGE_LANGUAGE,
  SET_GAME_TYPE,
  SET_IS_BET_CANCELED,
} from '../actionTypes';
import { SCENE_NAMES, KENO_TYPES } from '../../constants/game';
import { createReducer } from '../../helpers/general';

const { KENO_1 } = KENO_TYPES;
const { SELECT_TAB } = TAB_TYPES;
const { ADD_PAYOUT } = PAYOUT_TYPES;
const { SET_SELECTED_AMOUNT, PLUS_AMOUNT } = AMOUNT_TYPES;
const { ADD_TICKET, REPLACE_TICKETS } = TICKET_TYPES;
const { ADD_BTN, DELETE_BTN, RESET_BTNS, ADD_RANDOM_BTNS, ADD_GROUP_BTNS, DELETE_GROUP_BTNS } =
  BET_BTN_TYPES;
const { SET_DURATION } = DURATION_TYPES;
const { MENU_SCENE, LOADING_SCENE, GAME_SCENE } = SCENE_NAMES;
const { SHOW_BALL, SHOW_RAFFLE, HIDE_RAFFLE } = RAFFLE_TYPES;
const { LEADERBOARD_HIDE_POPUP, LEADERBOARD_SHOW_POPUP } = POPUP_TYPES;

const selectedBtns = [];
const confirmedTickets = [];

const selectedBetBtns = (state = selectedBtns, action) => {
  switch (action.type) {
    case ADD_BTN:
      return [...state, action.payload];
    case ADD_GROUP_BTNS:
      return [...state, ...action.payload];
    case DELETE_GROUP_BTNS:
      return action.payload;
    case DELETE_BTN:
      return state.filter((digit) => {
        return digit !== action.payload;
      });
    case RESET_BTNS:
      return selectedBtns;
    case ADD_RANDOM_BTNS:
      return action.payload;
    default:
      return state;
  }
};

const ticketReducer = (state = confirmedTickets, action) => {
  switch (action.type) {
    case ADD_TICKET:
      return [...state, action.payload];
    case REPLACE_TICKETS:
      return action.payload;
    default:
      return state;
  }
};

const selectedTab = (state = 'Current Game', action) => {
  switch (action.type) {
    case SELECT_TAB:
      return action.payload;
    default:
      return state;
  }
};

const selectedAmount = (state = '', action) => {
  switch (action.type) {
    case SET_SELECTED_AMOUNT:
      return action.payload;
    case PLUS_AMOUNT:
      return +state + action.payload[0] <= action.payload[1]
        ? +state + action.payload[0]
        : action.payload[1];
    default:
      return state;
  }
};

const lastPayout = (state = '', action) => {
  switch (action.type) {
    case ADD_PAYOUT:
      return action.payload;
    default:
      return state;
  }
};

const kenoType = (state = KENO_1, action) => {
  switch (action.type) {
    case SET_DURATION:
      return action.payload;
    default:
      return state;
  }
};

export const currentBall = (state = '', action) => {
  switch (action.type) {
    case SHOW_BALL:
      return action.payload;
    default:
      return state;
  }
};

export const activeScene = (state = LOADING_SCENE, action) => {
  switch (action.type) {
    case CHANGE_SCENE:
      return action.payload;
    default:
      return state;
  }
};

const openPopup = (state = false, action) => {
  switch (action.type) {
    case SHOW_POPUP:
      return !state;
    default:
      return state;
  }
};

const isRaffleActive = (state = false, action) => {
  switch (action.type) {
    case HIDE_RAFFLE:
      return false;
    case SHOW_RAFFLE:
      return true;
    default:
      return state;
  }
};

const raffleInfoReducer = (state = { raffleTickets: [] }, action) => {
  switch (action.type) {
    case ADD_RAFFLE_INFO:
      return action.payload;
    default:
      return state;
  }
};

const pulledDigitsReducer = (state = [], action) => {
  switch (action.type) {
    case PULLED_DIGIT_TYPES.SET_PULLED_DIGIT:
      return [...state, action.payload].filter(
        (digit, index, array) => array.indexOf(digit) === index,
      );
    case PULLED_DIGIT_TYPES.RESET_PULLED_DIGITS:
      return [];
    default:
      return state;
  }
};

const RaffleCombinationReducer = (state = [], action) => {
  switch (action.type) {
    case PULLED_DIGIT_TYPES.SET_RAFFLE_COMBINATION:
      return [action.payload, ...state];
    case PULLED_DIGIT_TYPES.RESET_RAFFLE_COMBINATION:
      return [];
    default:
      return state;
  }
};

const isCongratsTime = (state = false, action) => {
  switch (action.type) {
    case SET_CONGRATS:
      return action.value;
    default:
      return state;
  }
};

const isFetching = (state = false, action) => {
  switch (action.type) {
    case IS_FETCHING_TYPES.SET_IS_FETCHING:
      return true;
    case IS_FETCHING_TYPES.RESET_IS_FETCHING:
      return false;
    default:
      return state;
  }
};

const leaderboardPopupReducer = createReducer(false, (state) => {
  return {
    [LEADERBOARD_SHOW_POPUP]: true,
    [LEADERBOARD_HIDE_POPUP]: false,
  };
});

const kenoLanguage = (state = 'en', action) => {
  switch (action.type) {
    case CHANGE_LANGUAGE:
      if (action.value) {
        return action.value;
      }
      return state;
    default:
      return state;
  }
};

const gameType = (state = null, action) => {
  switch (action.type) {
    case SET_GAME_TYPE:
      if (action.value) {
        return action.value;
      }
      return state;
    default:
      return state;
  }
};

const optionsInitialState = {
  isBetCanceled: false,
};

const options = (state = optionsInitialState, action) => {
  switch (action.type) {
    case SET_IS_BET_CANCELED:
      return { ...state, isBetCanceled: action.value };
    default:
      return state;
  }
};

export default {
  leaderboardPopupReducer,
  kenoLanguage,
  isFetching,
  selectedBetBtns,
  ticketReducer,
  selectedTab,
  selectedAmount,
  lastPayout,
  kenoType,
  currentBall,
  activeScene,
  openPopup,
  isRaffleActive,
  raffleInfoReducer,
  pulledDigitsReducer,
  RaffleCombinationReducer,
  isCongratsTime,
  gameType,
  options,
};
