import React from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { plusAmount } from '../../../redux/actions/amountActions';
import { setIsBetCanceled } from '../../../redux/actions/optionActions';
import { useSoundConstext } from '../../../Context/SoundContext';
import { setMaxBetWarning } from '../../../redux/actions/errorActions';

const AmountButton = ({ amount, betAmount, plusAmount, betAmountOption, amountRanges }) => {
  const useSoundData = useSoundConstext();
  const dispatch = useDispatch();
  const { isBetCanceled } = useSelector((state) => state.options);
  const isSoundActive = useSelector((state) => state.toggleSoundReducer);
  const selectedAmount = useSelector((state) => state.selectedAmount);

  const clickHandler = () => {
    isSoundActive && useSoundData.addReduceAmount();
    isBetCanceled && dispatch(setIsBetCanceled(false));
    plusAmount([betAmount, betAmountOption.maxBet]);
    if (+selectedAmount + amount > betAmountOption.maxBet) {
      dispatch(setMaxBetWarning());
    }
  };

  return (
    <li
      className={`input-controls__item ${amountRanges.length === 3 ? 'three-input-controls' : ''}`}>
      <button onClick={clickHandler} className="input-control__button">
        +{amount}
      </button>
    </li>
  );
};

const mapStateToProps = ({ betAmountOptionReducer }) => ({
  betAmountOption: betAmountOptionReducer,
});

const mapDispatchToProps = {
  plusAmount,
};

export default connect(mapStateToProps, mapDispatchToProps)(AmountButton);
