// FOR PRODUCTION
export const url = '/api/v1'; //USE THIS IN PRODUCTION
export const getToken = () => localStorage.getItem('token');
export const getLanguage = () => localStorage.getItem('fallbackLanguage');

// FOR DEVELOPMENT
// export const getToken = () => 'test-frontend-token'
// export const url = 'https://keno.nrgaming.games/api/v1'; // DEVELOPMENT DB

// export const url = 'https://keno.nrgaming.games/api/v1';
