import React from 'react';
import './App.css';
import MenuScene from './MenuScene/Menuscene';
import Header from './Header/Header';
import GameScene from './GameScene/GameScene';
import { connect } from 'react-redux';
import { SCENE_NAMES } from '../constants/game';
import LoadingScene from './LoadingScene/LoadingScene';
import { goToGameScene } from '../redux/actions/activeSceneActions';
import ErrorsPopup from './ErrorsPopup/ErrorsPopup';

const { GAME_SCENE, LOADING_SCENE, MENU_SCENE } = SCENE_NAMES;

function App({ activeScene }) {
  return (
    <div className={`wrapper wrapper--welcome ${activeScene === MENU_SCENE && 'menu-background'}`}>
      {activeScene === LOADING_SCENE ? (
        <LoadingScene />
      ) : (
        <>
          <Header />
          {activeScene === GAME_SCENE ? <GameScene /> : <MenuScene />}
        </>
      )}
      <ErrorsPopup />
    </div>
  );
}

const mapStateToProps = ({ activeScene, gameVersionsReducer }) => ({
  activeScene,
  gameVersions: gameVersionsReducer,
});

const mapDispatchToProps = {
  goToGameScene,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
