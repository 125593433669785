import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useSoundConstext } from '../../../../../Context/SoundContext';

const TicketDigit = ({ digit, isDigitWon, isShirt }) => {
  const useSoundData = useSoundConstext();
  const isSoundActive = useSelector((state) => state.toggleSoundReducer);

  const isNumber7 = isShirt && +digit === 7;
  const isNumber10 = isShirt && +digit === 10;

  useEffect(() => {
    if (isDigitWon === true) {
      isSoundActive && useSoundData.stars();
    }
  }, [isDigitWon]);

  return (
    <li
      className={`current-ticket__number ${isDigitWon ? 'animate-win' : ''} ${
        isNumber7 && 'shirt7'
      } ${isNumber10 && 'shirt10'}  ${isShirt && 'emptyShirt'} `}>
      {digit ? (
        <>
          <div
            className={`current-ticket__number-text ${isShirt && 'shirt'} ${
              isNumber7 && 'shirt7'
            } ${isNumber10 && 'shirt10'}`}>
            <div className="current-ticket__number-text-wrapper">{digit}</div>
          </div>
          <span className="current-ticket__number-star" />
          {/* <span className="current-ticket__number-star" /> */}
          {/* <span className="current-ticket__number-star" /> */}
          {/* <span className="current-ticket__number-star" /> */}
        </>
      ) : (
        ''
      )}
    </li>
  );
};

export default TicketDigit;
